import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import ReactSwitch from "react-switch";
import apiService from "../core/sevice/detail";
import {
  getMethod,
  postMethod,
  exchangegetMethod,
} from "../core/sevice/common.api";
import toast, { Toaster } from "react-hot-toast";
import ReactPaginate from "react-paginate";
import { socket } from "../core/context/socket";

function InvestmentSettings() {

    const initialFormValue = {
        triangular_invest: "",
        triangular_limit: "",
        arbitrage_invest: "",
        arbitrage_limit: "",
        intra_invest: "",
        intra_limit: "",
      }

      const [validationnErr, setvalidationnErr] = useState("");
      const [formValue, setFormValue] = useState(initialFormValue);
      const [triangular_investValidate, settriangular_investValidate,triangular_investValidateref] = useState(false);
      const [triangular_limitValidate, settriangular_limitValidate,triangular_limitValidateref] = useState(false);
      const [arbitrage_investValidate, setarbitrage_investValidate,arbitrage_investValidateref] = useState(false);
      const [arbitrage_limitValidate, setarbitrage_limitValidate,arbitrage_limitValidateref] = useState(false);
      const [intra_investValidate, setintra_investValidate,intra_investValidateref] = useState(false);
      const [intra_limitValidate, setintra_limitValidate,intra_limitValidateref] = useState(false);
      const [isLoading, setIsLoading] = useState(false);
      const [buttonLoader, setbuttonLoader] = useState(false);

      const {
        triangular_invest,
        triangular_limit,
        arbitrage_invest,
        arbitrage_limit,
        intra_invest,
        intra_limit
      } = formValue;

      useEffect(() => {
        getInvestment();
      }, [0]);
  
      const handleChange = async (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        let formData = {...formValue, ...{[name]: value}};
        setFormValue(formData);
        validate(formData);
      };

      const validate = async (values) => {
        const errors = {};
  
        if (!values.triangular_invest) {
          errors.triangular_invest = "Required field !";
          settriangular_investValidate(true);
        } else {
            settriangular_investValidate(false);
        }
  
        if (!values.triangular_limit) {
          errors.triangular_limit = "Required field !";
          settriangular_limitValidate(true);
        } else {
           settriangular_limitValidate(false);
        }
  
        if (!values.arbitrage_invest) {
          errors.arbitrage_invest = "Required field !";
          setarbitrage_investValidate(true);
        }
        else {
          setarbitrage_investValidate(false);
        }

        if (!values.arbitrage_limit) {
            errors.arbitrage_limit = "Required field !";
            setarbitrage_limitValidate(true);
        }
        else {
            setarbitrage_limitValidate(false);
        }

        if (!values.intra_invest) {
            errors.intra_invest = "Required field !";
            setintra_investValidate(true);
        }
        else {
            setintra_investValidate(false);
        }

        if (!values.intra_limit) {
            errors.intra_limit = "Required field !";
            setintra_limitValidate(true);
        }
        else {
            setintra_limitValidate(false);
        }
    
        setvalidationnErr(errors);
        return errors;
      }

      const submit = async () => {
        try {
            validate(formValue);
            console.log("its came");
        } catch (error) {
        }
      }

      const getInvestment = async () => {
        try {
          var data = {
            apiUrl: apiService.getInvestment,
          };
          setIsLoading(true);
          var resp = await getMethod(data);
          
          if (resp.status == true) {
            setIsLoading(false);
            formValue.triangular_invest = resp.data.triangular_invest;
            formValue.triangular_limit = resp.data.triangular_limit;
            formValue.arbitrage_invest = resp.data.arbitrage_invest;
            formValue.arbitrage_limit = resp.data.arbitrage_limit;
            formValue.intra_invest = resp.data.intra_invest;
            formValue.intra_limit = resp.data.intra_limit;
          }
        } catch (error) {}
      };

      const formSubmit = async () => {
        validate(formValue);
        if (triangular_investValidateref.current === false && triangular_limitValidateref.current === false &&
            arbitrage_investValidateref.current === false && arbitrage_limitValidateref.current === false &&
            intra_investValidateref.current === false && intra_limitValidateref.current === false
        ) {
          var data = {
            apiUrl: apiService.updateInvestment,
            payload: formValue,
          };
          setbuttonLoader(true);
          var resp = await postMethod(data);
            if(resp.status == true) {
                toast.success(resp.Message);
                getInvestment();
            }
            else {
                setbuttonLoader(false);
                toast.error(resp.Message);
            }
        }
      };

  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 4000,
        }}
      />
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 col-sm-3">
            <Sidebar />
          </div>
          <div className="col-lg-10 col-sm-9">
            <div className="container-fluid settings-container">
              {isLoading == true ? (
                <>
                  <div className="loader-css">
                    <img
                      src={require("../images/Dual Ball@1x-1.0s-200px-200px.gif")}
                      alt="loader"
                      className="loader-gif"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-lg-6">
                      <div className="setting_blw_col_title">Investment Settings</div>
                      <div className="setting_blw_col2">

                        <div className="input-settings">
                          <label>Investment capital(USDT) for Triangular</label>
                          <input
                              type="number"
                              className="login-input mt-1"
                              name="triangular_invest"
                              value={triangular_invest}
                              onChange={handleChange}
                              min = "0"
                            />
                            {triangular_investValidate == true ? (
                                <p className="errorcss">
                                {" "}
                                {validationnErr.triangular_invest}{" "}
                                </p>
                            ) : (
                                ""
                            )}
                        </div>
                        
                        <div className="input-settings">
                          <label>Per Trade Limit(USDT) for Triangular</label>
                          <input
                              type="number"
                              className="login-input mt-1"
                              name="triangular_limit"
                              value={triangular_limit}
                              onChange={handleChange}
                              min = "0"
                          />
                           {triangular_limitValidate == true ? (
                                <p className="errorcss">
                                {" "}
                                {validationnErr.triangular_limit}{" "}
                                </p>
                            ) : (
                                ""
                            )}
                        </div>


                        <div className="input-settings">
                          <label>Investment capital(USDT) for Arbitrage</label>
                          <input
                              type="number"
                              className="login-input mt-1"
                              name="arbitrage_invest"
                              value={arbitrage_invest}
                              onChange={handleChange}
                              min = "0"
                            />
                            {arbitrage_investValidate == true ? (
                                <p className="errorcss">
                                {" "}
                                {validationnErr.arbitrage_invest}{" "}
                                </p>
                            ) : (
                                ""
                            )}
                        </div>
                        
                        <div className="input-settings">
                          <label>Per Trade Limit(USDT) for Arbitrage</label>
                          <input
                              type="number"
                              className="login-input mt-1"
                              name="arbitrage_limit"
                              value={arbitrage_limit}
                              onChange={handleChange}
                              min = "0"
                          />
                           {arbitrage_limitValidate == true ? (
                                <p className="errorcss">
                                {" "}
                                {validationnErr.arbitrage_limit}{" "}
                                </p>
                            ) : (
                                ""
                            )}
                        </div>


                        <div className="input-settings">
                          <label>Investment capital(USDT) for Intra</label>
                          <input
                              type="number"
                              className="login-input mt-1"
                              name="intra_invest"
                              value={intra_invest}
                              onChange={handleChange}
                              min = "0"
                            />
                          {intra_investValidate == true ? (
                                <p className="errorcss">
                                {" "}
                                {validationnErr.intra_invest}{" "}
                                </p>
                            ) : (
                                ""
                            )}
                        </div>
                        
                        <div className="input-settings">
                          <label>Per Trade Limit(USDT) for Intra</label>
                          <input
                              type="number"
                              className="login-input mt-1"
                              name="intra_limit"
                              value={intra_limit}
                              onChange={handleChange}
                              min = "0"
                          />
                          {intra_limitValidate == true ? (
                                <p className="errorcss">
                                {" "}
                                {validationnErr.intra_limit}{" "}
                                </p>
                            ) : (
                                ""
                            )}
                        </div>

                        <div>
                        
                        {buttonLoader == false ? (
                        <button className="login-submit-btn mt-4" onClick={formSubmit}>
                            <span className="login-submit">Submit</span>
                        </button>
                        ) : (
                        <button className="login-submit-btn mt-4">
                            <span className="login-submit">Loading</span>
                        </button>
                        )}
                    
                        
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

    
    </div>
  );
}

export default InvestmentSettings;
